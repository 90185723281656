import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Suspense } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Home from "pages/Home";
import ModalChoice from "components/ModalChoice";
import AboutUs from "pages/AboutUs";
import FundManagement from "pages/FundManagement";
import DiscretionaryManagement from "pages/DiscretionaryManagement";
import FinancialProductCreation from "pages/FinancialProductCreation";
import ContactUs from "pages/ContactUs";

function App() {
  return (
    <div className="App">
      <Header />
      <ModalChoice />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/fund-management" element={<FundManagement />} />
          <Route
            path="/discretionary-management"
            element={<DiscretionaryManagement />}
          />
          <Route
            path="/financial-product-creation"
            element={<FinancialProductCreation />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
