import React from "react";

import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

function BGHero({ params }) {
  const { t } = useTranslation();

  const textInclusive = t("main.bgHeroInclusiva");
  const textTransparent = t("main.bgHeroInnovativa");
  const textInnovative = t("main.bgHeroTrasparente");

  return (
    <div className="bg-image d-flex justify-content-center align-items-center rigsavecapital-background-hero">
      <div className="container">
        <div className="mx-auto">
          <h2 className="pb-3 text-center justify-content-center d-lg-flex">
            {params.text}
            {params.isTypewriterOn && (
              <Typewriter
                options={{
                  strings: [textInclusive, textTransparent, textInnovative],
                  autoStart: true,
                  loop: true,
                }}
              />
            )}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default BGHero;
