import React from "react";

import companyLogo from "../assets/logonuovo.png";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "react-responsive";

import "../css/Header.css";
import TranslatorComponentMobile from "./TranslatorComponentMobile";
import TranslatorComponent from "./TranslatorComponent";
import { Link } from "react-router-dom";

function Header() {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div className="header-container">
      <Navbar expand="lg" className="p-0">
        <Container>
          <Navbar.Brand className="p-0">
            <Link to="/">
              <img
                width={120}
                height={110}
                src={companyLogo}
                alt="Rigsave logo"
              />
            </Link>
          </Navbar.Brand>

          {isTabletOrMobile && <TranslatorComponentMobile />}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {isDesktop && <TranslatorComponent />}
            <Nav className="mr-auto">
              <Link className="link-first-level ps-3" to="/about-us">
                {t("main.header.aboutUs")}
              </Link>
              <NavDropdown
                title={t("main.header.servicesDropdown.service")}
                className="dropdown link-first-level"
              >
                <Link className="dropdown-item pt-2 pb-2" to="/fund-management">
                  {t("main.header.servicesDropdown.fundManagement")}
                </Link>
                <Link
                  className="dropdown-item pt-2 pb-2"
                  to="/discretionary-management"
                >
                  {t("main.header.servicesDropdown.gestioneDisc")}
                </Link>

                <Link
                  className="dropdown-item pt-2 pb-2"
                  to="/financial-product-creation"
                >
                  {t("main.header.servicesDropdown.creationProd")}
                </Link>
              </NavDropdown>
              <Nav.Link
                className="link-first-level"
                href="https://rigsavecapital.netlify.app/#/login"
              >
                {t("main.header.icashly")}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
