import React from "react";
import { useTranslation } from "react-i18next";

import "../css/TranslatorComponent.css";

import Select from "react-select";
import Flag from "react-world-flags";

function TranslatorComponentMobile() {
  const { i18n } = useTranslation();

  const options = [
    { value: "en", label: "ENG", flag: "gbr" },
    { value: "it", label: "ITA", flag: "ita" },
    { value: "de", label: "DEU", flag: "deu" },
  ];

  return (
    <div className="picker-lang-rigsave pe-3">
      <Select
        defaultValue={{ value: "en", label: "ENG", flag: "gbr" }}
        onChange={(obj) => {
          i18n.changeLanguage(obj.value);
        }}
        options={options}
        formatOptionLabel={(country) => (
          <div className="country-option d-flex px-0 text-center justify-content-center">
            <Flag className="pe-2" code={country.flag} width="35" />
          </div>
        )}
      />
    </div>
  );
}

export default TranslatorComponentMobile;
