import React, { useRef } from "react";

import { useTranslation } from "react-i18next";

import "../css/ContactUs.css";
import BGHero from "components/BGHero";

function ContactUs() {
  const { t } = useTranslation();

  const textHome = t("main.fundManagement.contactUs");
  const form = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.target.reset();
  };

  return (
    <div id="contactUs">
      <BGHero params={{ text: textHome, isTypewriterOn: false }} />
      <div className="container container-ufficio-stampa text-start">
        <div className="mt-5">
          <form ref={form} onSubmit={handleSubmit} autoComplete="off">
            <fieldset disabled="disabled">
              <p className="mt-2 mb-2">{t("main.contactUs.name")}</p>
              <input
                className="px-2 pt-2 pb-2 mt-1 mb-3"
                name="name"
                placeholder={t("main.contactUs.name")}
                required
              />
              <p className="mt-2 mb-2">Email</p>
              <input
                type="email"
                className="px-2 pt-2 pt-2 pb-2 mt-1 mb-3"
                name="email"
                placeholder="Email"
                required
              />
              <p className="mt-2 mb-2">{t("main.contactUs.message")}</p>
              <textarea
                className="px-2 pt-2 mt-1 mb-4"
                name="subject"
                placeholder={t("main.contactUs.message")}
                required
              />
              <button className="mb-5 pt-3 pb-3" type="submit" disabled>
                {t("main.contactUs.send")}
              </button>
            </fieldset>
          </form>
        </div>
        <div className="mb-5 pb-5">
          <h3 className="text-center">
            {t("main.contactUs.labelInfo")} info@rigsavecapital.com
          </h3>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
