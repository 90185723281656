import React from "react";

import advisoryImg from "../assets/Advisory.png";
import gestioneFondiImg from "../assets/Gestione-Fondi.png";
import gestioneDiscrezionaleImg from "../assets/GestioneDiscrezionale.png";
import strutturazioneImg from "../assets/Strutturazione.png";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BGHero from "components/BGHero";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import giovanniG from "../assets/Giovanni_Finale.png";
import micheleB from "../assets/Michele_Finale.png";
import salvatoreG from "../assets/Salvatore_Finale.png";

import "../css/Home.css";

function Home() {
  const { t } = useTranslation();

  const textHome = t("main.bgHero");

  return (
    <div id="home">
      <BGHero params={{ text: textHome, isTypewriterOn: true }} />
      <div className="container container-vision mb-5 pb-5">
        <div className="d-lg-flex mt-5 pt-5">
          <div className="col-lg-4 img-vision-wrapper text-end">
            <img src={gestioneDiscrezionaleImg} alt="Advisory" />
          </div>
          <div className="col-lg-5 ps-5 text-start">
            <h3>{t("main.home.gestioneDisc")}</h3>
            <p className="mb-4">{t("main.home.gestioneDiscDesc")}</p>
            <div className="button-link-group">
              <Link to={"/discretionary-management"}>
                {t("main.home.findOutMore")}{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
        <div className="d-lg-flex vision-odd-container mt-5 pt-5">
          <div className="col-lg-3"></div>
          <div className="col-lg-5 pe-5 text-end">
            <h3>{t("main.home.fundManagement")}</h3>
            <p className="mb-4">{t("main.home.fundManagementDesc")}</p>
            <div className="d-lg-flex justify-content-end">
              <div className="button-link-group">
                <Link to={"/fund-management"}>
                  {t("main.home.findOutMore")}{" "}
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 img-vision-wrapper text-start">
            <img src={gestioneFondiImg} alt="Advisory" />
          </div>
        </div>
        <div className="d-lg-flex mt-5 pt-5">
          <div className="col-lg-4 img-vision-wrapper text-end">
            <img src={strutturazioneImg} alt="Advisory" />
          </div>
          <div className="col-lg-5 ps-5 text-start">
            <h3>{t("main.home.structureProd")}</h3>
            <p className="mb-4">{t("main.home.structureProdDesc")}</p>
            <div className="button-link-group">
              <Link to={"/financial-product-creation"}>
                {t("main.home.findOutMore")}{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
        <div className="d-lg-flex vision-odd-container mt-5 pt-5">
          <div className="col-lg-3"></div>
          <div className="col-lg-5 pe-5 text-end">
            <h3>{t("main.home.advisory")}</h3>
            <p className="mb-4">{t("main.home.advisoryDesc")}</p>
            <div className="d-lg-flex justify-content-end">
              <div className="button-link-group-comingsoon">
                {t("main.home.comingSoon")}{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 img-vision-wrapper text-start">
            <img src={advisoryImg} alt="Advisory" />
          </div>
        </div>
      </div>
      <div className="container container-team-restricted d-lg-flex justify-content-center text-center mt-5 pt-5 mb-5 pb-3">
        <div className="px-5">
          <img src={giovanniG} alt="Giovanni Gervasi" />
          <div className="pt-3 pb-2 name">Giovanni Gervasi</div>
          <p>
            Co-founder, Investment Committee Member, Portfolio Manager and
            Investment Advisor
          </p>
        </div>
        <div className="px-5">
          <img src={micheleB} alt="Michele Basilicata" />
          <div className="pt-3 pb-2 name">Michele Basilicata</div>
          <p>
            Co-founder, Director, Country Head Italy, Relationship Managing
            Director
          </p>
        </div>
        <div className="px-5">
          <img src={salvatoreG} alt="Salvatore Gervasi" />
          <div className="pt-3 pb-2 name">Salvatore Gervasi</div>
          <p>Co-founder, Director and Investment Management Vice President</p>
        </div>
      </div>
      <div className="container pb-5 mb-5">
        <Link className="button-link-team" to={"/about-us"}>
          {t("main.home.discoverTeamButton")}{" "}
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
    </div>
  );
}

export default Home;
