import BGHero from "components/BGHero";
import React, { useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import "../css/DiscretionaryManagement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightDots,
  faDownload,
  faLandmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getUrl } from "aws-amplify/storage";

import managementFeeImg from "../assets/Management-Fee-Nov21.png";
import { ProgressBar } from "react-bootstrap";

import competenzaImg from "../assets/Competenza.png";
import dynamicImg from "../assets/Dynamic.png";
import flexbileImg from "../assets/Flexible.png";

function DiscretionaryManagement() {
  const { t } = useTranslation();

  const textHome = t("main.discretionaryManagement.bgHero");

  const [conservativa2021, setConservativa2021] = useState("");
  const [conservativaGennaio2022, setConservativaGennaio2022] = useState("");
  const [conservativaFebbario2022, setConservativaFebbraio2022] = useState("");
  const [conservativaMarzo2022, setConservativaMarzo2022] = useState("");
  const [conservativaAprile2022, setConservativaAprile2022] = useState("");
  const [conservativaMaggio2022, setConservativaMaggio2022] = useState("");
  const [conservativaLuglio2022, setConservativaLuglio2022] = useState("");

  const [flexible2021, setFlexible2021] = useState("");
  const [flexibleGennaio2022, setFlexibleGennaio2022] = useState("");
  const [flexibleFebbario2022, setFlexibleFebbraio2022] = useState("");
  const [flexibleMarzo2022, setFlexibleMarzo2022] = useState("");
  const [flexibleAprile2022, setFlexibleAprile2022] = useState("");
  const [flexibleMaggio2022, setFlexibleMaggio2022] = useState("");

  const [dynamic2021, setDynamic2021] = useState("");
  const [dynamicGennaio2022, setDynamicGennaio2022] = useState("");
  const [dynamicFebbario2022, setDynamicFebbraio2022] = useState("");
  const [dynamicMarzo2022, setDynamicMarzo2022] = useState("");
  const [dynamicAprile2022, setDynamicAprile2022] = useState("");
  const [dynamicMaggio2022, setDynamicMaggio2022] = useState("");

  async function downlaodFiles() {
    setConservativa2021(
      (
        await getUrl({
          key: "conservativeLine/Performance-Contribution-Report-2021-Conservative.pdf",
        })
      ).url.toString()
    );
    setConservativaGennaio2022(
      (
        await getUrl({
          key: "conservativeLine/Performance-Contribution-Report-Gennaio-2022-Conservative.pdf",
        })
      ).url.toString()
    );
    setConservativaFebbraio2022(
      (
        await getUrl({
          key: "conservativeLine/Template-Performance-Contribution-Report-Conservative_Feb-22.pdf",
        })
      ).url.toString()
    );
    setConservativaMarzo2022(
      (
        await getUrl({
          key: "conservativeLine/Template-Performance-Contribution-Report-Conservative_Mar-22.pdf",
        })
      ).url.toString()
    );
    setConservativaAprile2022(
      (
        await getUrl({
          key: "conservativeLine/Template-Performance-Contribution-Report-Conservative_Apr-22.pdf",
        })
      ).url.toString()
    );
    setConservativaMaggio2022(
      (
        await getUrl({
          key: "conservativeLine/Template-Performance-Contribution-Report-Conservative_Mag-22.pdf",
        })
      ).url.toString()
    );
    setConservativaLuglio2022(
      (
        await getUrl({
          key: "conservativeLine/Performance-Contribution-Report-Model-Conservative-July-2022.pdf",
        })
      ).url.toString()
    );

    setFlexible2021(
      (
        await getUrl({
          key: "flexibleLine/Performance-Contribution-Report-2021-Flexible.pdf",
        })
      ).url.toString()
    );
    setFlexibleGennaio2022(
      (
        await getUrl({
          key: "flexibleLine/Performance-Contribution-Report-Gennaio-2022-Flexible.pdf",
        })
      ).url.toString()
    );
    setFlexibleFebbraio2022(
      (
        await getUrl({
          key: "flexibleLine/Template-Performance-Contribution-Report-Flexible_Feb-22.pdf",
        })
      ).url.toString()
    );
    setFlexibleMarzo2022(
      (
        await getUrl({
          key: "flexibleLine/Template-Performance-Contribution-Report-Flexible_Mar-22.pdf",
        })
      ).url.toString()
    );
    setFlexibleAprile2022(
      (
        await getUrl({
          key: "flexibleLine/Template-Performance-Contribution-Report-Flexible_Apr-22.pdf",
        })
      ).url.toString()
    );
    setFlexibleMaggio2022(
      (
        await getUrl({
          key: "flexibleLine/Template-Performance-Contribution-Report-Flexible_Mag-22.pdf",
        })
      ).url.toString()
    );

    setDynamic2021(
      (
        await getUrl({
          key: "dynamicLine/Performance-Contribution-Report-2021-Dynamic.pdf",
        })
      ).url.toString()
    );
    setDynamicGennaio2022(
      (
        await getUrl({
          key: "dynamicLine/Performance-Contribution-Report-Gennaio-2022-Dynamic.pdf",
        })
      ).url.toString()
    );
    setDynamicFebbraio2022(
      (
        await getUrl({
          key: "dynamicLine/Template-Performance-Contribution-Report-Dynamic_Feb-22.pdf",
        })
      ).url.toString()
    );
    setDynamicMarzo2022(
      (
        await getUrl({
          key: "dynamicLine/Template-Performance-Contribution-Report-Dynamic_Mar-22.pdf",
        })
      ).url.toString()
    );
    setDynamicAprile2022(
      (
        await getUrl({
          key: "dynamicLine/Template-Performance-Contribution-Report-Dynamic_Apr-22_1.pdf",
        })
      ).url.toString()
    );
    setDynamicMaggio2022(
      (
        await getUrl({
          key: "dynamicLine/Template-Performance-Contribution-Report-Dynamic_Mag-22.pdf",
        })
      ).url.toString()
    );
  }
  useEffect(() => {
    downlaodFiles();
  });

  return (
    <div id="discretionary-management">
      <BGHero params={{ text: textHome, isTypewriterOn: false }} />
      <div className="discretionary-management-text-wrapper">
        <div className="container text-center pt-5 pb-5 mb-5">
          {t("main.discretionaryManagement.subText")}
        </div>
      </div>
      <div className="container container-strengths mt-5 mb-5 pb-5">
        <h2 className="mt-5 pt-4">
          {t("main.discretionaryManagement.strengths")}
        </h2>
        <div className="d-lg-flex mt-5 pt-4">
          <div className="col-lg-4 d-block px-5">
            <div>
              <FontAwesomeIcon
                className="icon-strengths pb-4"
                icon={faArrowUpRightDots}
              />
            </div>
            <h3>{t("main.discretionaryManagement.efficiency")}</h3>
            <Trans>{t("main.discretionaryManagement.efficiencyDesc")}</Trans>
          </div>
          <div className="col-lg-4 d-block px-5">
            <div>
              <FontAwesomeIcon
                className="icon-strengths pb-4"
                icon={faPenToSquare}
              />
            </div>
            <h3>{t("main.discretionaryManagement.personalization")}</h3>
            <Trans>
              {t("main.discretionaryManagement.personalizationDesc")}
            </Trans>
          </div>
          <div className="col-lg-4 d-block px-5">
            <div>
              <FontAwesomeIcon
                className="icon-strengths pb-4"
                icon={faLandmark}
              />
            </div>
            <h3>{t("main.discretionaryManagement.savings")}</h3>
            <Trans>{t("main.discretionaryManagement.savingsDesc")}</Trans>
          </div>
        </div>
      </div>
      <div className="container container-lines mb-5">
        <div className="d-lg-flex mb-5">
          <div className="col-lg-3 text-end pe-5 pt-5">
            <img
              className="lines-img"
              src={competenzaImg}
              alt="Conservative line"
            />
          </div>
          <div className="col-lg-9 pe-5 text-start">
            <h3 className="mb-2">
              {t("main.discretionaryManagement.lineaConservativaTitle")}
            </h3>
            <strong className="sub-text-color mb-1">
              {'"'}
              {t("main.discretionaryManagement.conservativeSubText")}
              {'"'} {"(Philip Fisher)"}
            </strong>
            <p className="mb-4">
              {t("main.discretionaryManagement.conservativeDesc")}
            </p>
            <h4>{t("main.discretionaryManagement.leverage")}</h4>
            <ProgressBar className="mb-3" now={20} />
            <h4>{t("main.discretionaryManagement.riskProfile")}</h4>
            <ProgressBar
              className="mb-3"
              now={23}
              label={t("main.discretionaryManagement.labelConservativa")}
            />
            <h4>{t("main.discretionaryManagement.timeHorizon")}</h4>
            <ProgressBar
              className="mb-3"
              now={23}
              label={t("main.discretionaryManagement.labelConservativa")}
            />
            <h4 className="pt-4">
              {t("main.discretionaryManagement.performanceReport")}
            </h4>

            <div className="mt-4 mb-5 container-links d-lg-flex">
              <div className="col-lg-6">
                <a href={conservativa2021} target="_blank" rel="noreferrer">
                  2021 <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  href={conservativaGennaio2022}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("main.discretionaryManagement.january")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  href={conservativaFebbario2022}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("main.discretionaryManagement.february")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  href={conservativaMarzo2022}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("main.discretionaryManagement.march")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  href={conservativaAprile2022}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("main.discretionaryManagement.april")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  href={conservativaMaggio2022}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("main.discretionaryManagement.may")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  href={conservativaLuglio2022}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("main.discretionaryManagement.july")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex mb-5">
          <div className="col-lg-3 text-end pe-5 pt-5">
            <img
              className="lines-img"
              src={flexbileImg}
              alt="Conservative line"
            />
          </div>
          <div className="col-lg-9 pe-5 text-start">
            <h3 className="mb-3">
              {t("main.discretionaryManagement.lineaFlexibleTitle")}
            </h3>
            <strong className="sub-text-color mb-1">
              {'"'}
              {t("main.discretionaryManagement.flexibleSubText")}
              {'"'} {"(Warren Buffett)"}
            </strong>
            <p className="mb-4">
              {t("main.discretionaryManagement.flexibleeDesc")}
            </p>
            <h4>{t("main.discretionaryManagement.leverage")}</h4>
            <ProgressBar className="mb-3" now={48} />
            <h4>{t("main.discretionaryManagement.riskProfile")}</h4>
            <ProgressBar
              className="mb-3"
              now={71}
              label={t("main.discretionaryManagement.labelFlexible")}
            />
            <h4>{t("main.discretionaryManagement.timeHorizon")}</h4>
            <ProgressBar
              className="mb-3"
              now={71}
              label={t("main.discretionaryManagement.labelFlexible")}
            />
            <h4 className="pt-4">
              {t("main.discretionaryManagement.performanceReport")}
            </h4>
            <div className="mt-4 mb-5 container-links d-lg-flex">
              <div className="col-lg-6">
                <a href={flexible2021} target="_blank" rel="noreferrer">
                  2021 <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={flexibleGennaio2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.january")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={flexibleFebbario2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.february")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={flexibleMarzo2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.march")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={flexibleAprile2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.april")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={flexibleMaggio2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.may")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex mb-5">
          <div className="col-lg-3 text-end pe-5 pt-5">
            <img
              className="lines-img"
              src={dynamicImg}
              alt="Conservative line"
            />
          </div>
          <div className="col-lg-9 pe-5 text-start">
            <h3 className="mb-3">
              {t("main.discretionaryManagement.lineaDynamicTitle")}
            </h3>
            <strong className="sub-text-color mb-1">
              {'"'}
              {t("main.discretionaryManagement.dynamicSubText")}
              {'"'} {"(Ray Dalio)"}
            </strong>
            <p className="mb-4">
              {t("main.discretionaryManagement.dynamicDesc")}
            </p>
            <h4>{t("main.discretionaryManagement.leverage")}</h4>
            <ProgressBar className="mb-3" now={77} />
            <h4>{t("main.discretionaryManagement.riskProfile")}</h4>
            <ProgressBar
              className="mb-3"
              now={86}
              label={t("main.discretionaryManagement.labelDynamic")}
            />
            <h4 className="pt-4">
              {t("main.discretionaryManagement.timeHorizon")}
            </h4>
            <ProgressBar
              className="mb-3"
              now={71}
              label={t("main.discretionaryManagement.labelFlexible")}
            />
            <h4 className="pt-4">
              {t("main.discretionaryManagement.performanceReport")}
            </h4>
            <div className="mt-4 mb-5 container-links d-lg-flex">
              <div className="col-lg-6">
                <a href={dynamic2021} target="_blank" rel="noreferrer">
                  2021 <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={dynamicGennaio2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.january")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={dynamicFebbario2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.february")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={dynamicMarzo2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.march")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={dynamicAprile2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.april")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
              <div className="col-lg-6">
                <a href={dynamicMaggio2022} target="_blank" rel="noreferrer">
                  {t("main.discretionaryManagement.may")} 2022{" "}
                  <FontAwesomeIcon className="ps-1" icon={faDownload} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-management-fee pb-5 pt-5">
        <div className="container text-start">
          <h3 className="mb-4">
            {t("main.discretionaryManagement.managementFeeTitle")}
          </h3>
          <div className="d-lg-flex">
            <div className="col-lg-6 text-start">
              {t("main.discretionaryManagement.managementFeeText")}
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className="d-lg-flex">
            <div className="col-lg-6 text-center justify-content-center mt-5">
              <p>
                <strong>{t("main.discretionaryManagement.retail")}</strong>
              </p>
              <img
                className="img-management-fee"
                src={managementFeeImg}
                alt="Management fee"
              />
            </div>
            <div className="col-lg-6 text-center justify-content-center mt-5">
              <p>
                <strong>
                  {t("main.discretionaryManagement.professional")}
                </strong>
              </p>
              <img
                className="img-management-fee"
                src={managementFeeImg}
                alt="Management fee"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-performance-fee">
        <div className="col-lg-6 text-start pt-5 ms-5 ps-5">
          <h3>{t("main.discretionaryManagement.performanceFee")}</h3>
          <Trans>{t("main.discretionaryManagement.performanceFeeText")}</Trans>
        </div>
        <div className="col-lg-6"></div>
      </div>
      <div className="container mt-4 mb-5 pt-5 pb-4 px-5">
        <h2>{t("main.discretionaryManagement.entraInRigsave")}</h2>
        <p>{t("main.discretionaryManagement.entraInRigsaveSubText")}</p>
        <div className="d-lg-flex">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 text-start ps-4">
            <p>
              <strong>
                {"1)"} {t("main.discretionaryManagement.checkUp")}
              </strong>
            </p>
            <p>
              <strong>
                {"2)"} {t("main.discretionaryManagement.onboarding")}
              </strong>
            </p>
            <p>
              <strong>
                {"3)"} {t("main.discretionaryManagement.contractSign")}
              </strong>
            </p>
            <p>
              <strong>
                {"4)"} {t("main.discretionaryManagement.portfolioCreation")}
              </strong>
            </p>
            <p>
              <strong>
                {"5)"} {t("main.discretionaryManagement.reporting")}
              </strong>
            </p>
          </div>
          <div className="col-lg-4"></div>
        </div>
        <Link className="link-contact-us mt-4" to={"/contact-us"}>
          {t("main.fundManagement.contactUs")}{" "}
        </Link>
      </div>
    </div>
  );
}

export default DiscretionaryManagement;
