import BGHero from "components/BGHero";
import React from "react";

import { Trans, useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import "../css/FinancialProductCreation.css";
import { Link } from "react-router-dom";

function FinancialProductCreation() {
  const { t } = useTranslation();

  const textHome = t("main.financialProduct.bgHero");

  return (
    <div id="financial-product">
      <BGHero params={{ text: textHome, isTypewriterOn: false }} />
      <div className="wrapper-struttura-gruppo pt-5 pb-5 mb-4">
        <div className="container px-5">
          <Trans>{t("main.financialProduct.descParag")}</Trans>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <h2 className="mt-4 pt-3 mb-5 pb-5">
          {t("main.financialProduct.ourProducts")}
        </h2>
        <div className="products-tabs-wrapper pb-4">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      {t("main.financialProduct.monthlyOpportunity")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      {t("main.financialProduct.certificate1")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      {t("main.financialProduct.certificate2")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="wrapper-products">
                      <div className="mt-3 mb-5 text-start">
                        <strong>
                          {t("main.financialProduct.description")}
                        </strong>
                        <Trans>
                          {t("main.financialProduct.descriptionText")}
                        </Trans>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>ISIN</strong>: <span>DE000A2R7MA3</span>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>
                          {t("main.financialProduct.listingMarket")}
                        </strong>
                        :{" "}
                        <span>
                          {t("main.financialProduct.monacoStock")};{" "}
                          {t("main.financialProduct.viennaStock")}
                        </span>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>{t("main.financialProduct.issuer")}</strong>:{" "}
                        <span>Pareto Securities sarl</span>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="wrapper-products">
                      <div className="mt-3 mb-5 text-start">
                        <strong>
                          {t("main.financialProduct.description")}
                        </strong>
                        <Trans>
                          {t(
                            "main.financialProduct.descriptionTextCertificate1"
                          )}
                        </Trans>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>ISIN</strong>: <span>CH1129849316</span>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>
                          {t("main.financialProduct.listingMarket")}
                        </strong>
                        : <span>EuroTLX</span>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>{t("main.financialProduct.issuer")}</strong>:{" "}
                        <span>Leonteq Securities AG</span>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="wrapper-products">
                      <div className="mt-3 mb-5 text-start">
                        <strong>
                          {t("main.financialProduct.description")}
                        </strong>
                        <Trans>
                          {t(
                            "main.financialProduct.descriptionTextCertificate2"
                          )}
                        </Trans>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>ISIN</strong>: <span>CH1129841891</span>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>
                          {t("main.financialProduct.listingMarket")}
                        </strong>
                        : <span>EuroTLX</span>
                      </div>
                      <div className="mt-4 mb-5 text-start">
                        <strong>{t("main.financialProduct.issuer")}</strong>:{" "}
                        <span>Leonteq Securities AG</span>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <div className="mt-5 mb-5 pt-5 pb-5 px-5">
          <h2>{t("main.financialProduct.structuredProducth2")}</h2>
          <p>{t("main.financialProduct.structuredProductp")}</p>
          <Link className="link-contact-us mt-5" to={"/contact-us"}>
            {t("main.fundManagement.contactUs")}{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FinancialProductCreation;
